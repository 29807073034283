<template>
  <div>
    <div v-html="item.MediumDescription" class="medium-description mb-3" />

    <current-rental-information :fullwidth="true" />

    <hr />
    <div v-if="selectedWarehouse !== ''">
      <span v-if="!itemPlannerLoaded">
        <icon-spinner class="fa-spin" />
      </span>

      <b-form v-if="itemPlannerLoaded">
        <b-row>
          <b-col>
            <small>{{ this.$t('maximum-reservation-early') }}</small>
          </b-col>
        </b-row>
      </b-form>

      <div v-if="startDate && endDate && itemPlannerLoaded" class="mt-3">
        <div v-if="partiallyAvailable" class="partially-available">
          <h5>{{ this.$t('partially-available-in') }} {{ warehouse }}</h5>
          <p>
            {{ this.$t('one-or-more-items-in-package') }}
          </p>
        </div>
        <div>
          <ItemPlannerItem
            ref="itemPlanner"
            :item="requestedItemData"
            :start-date="startDate"
            :end-date="endDate"
            :date-editable="dateEditable"
            :updating-item="updatingItem"
            :item-data="item"
            @updateItemData="refreshItemData"
            item-type="Item"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ItemPlannerItem from './ItemPlannerItem'
import { getItemStock } from '@/services/ItemService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import Vue from 'vue'
import IconSpinner from '@/components/icons/IconSpinner'
import CurrentRentalInformation from '@/components/header/CurrentRentalInformation'

dayjs.extend(customParseFormat)

export default {
  components: {
    CurrentRentalInformation,
    IconSpinner,
    ItemPlannerItem
  },
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      disabled: [0, 6],
      position: 'left',
      format: 'dd/MM/yyyy',
      clear: false,
      lang: 'nl',
      placeholder: 'Selecteer een datum',
      bus: new Vue(),
      maxDate: dayjs()
        .add(25, 'years')
        .format('DD/MM/YYYY'),
      requestedItemData: {},
      partiallyAvailable: false,
      updatingItem: true,
      tabIndex: 0,
      itemReferences: {},
      itemPlannerLoaded: false,
      initialLoad: true
    }
  },
  computed: {
    selectedWarehouse: function() {
      return this.$store.state.activeWarehouse
    },
    warehouse: function() {
      return this.$store.state.activeWarehouse
    },
    warehouses: function() {
      if (!this.item.Warehouses) return []
      return this.item.Warehouses.map(warehouse => warehouse.WarehouseID)
    },
    startDate: function() {
      return this.$store.state.DateTimeBusinessStart
    },
    endDate: function() {
      return this.$store.state.DateTimeBusinessEnd
    },
    isLoggedIn: function() {
      if (this.$store.getters.user.UserName) {
        return true
      } else {
        return false
      }
    },
    dateEditable: function() {
      if (this.updatingItem) return false
      return this.$store.getters.cartCount < 1
    },
    currentWarehouse: function() {
      if (this.$store.getters.cartCache) {
        return this.$store.getters.cartCache.WarehouseID
          ? this.$store.getters.cartCache.WarehouseID
          : ''
      } else {
        return ''
      }
    },
    fitsInRentalDuration: function() {
      if (!this.requestedItemData.MaxReservationDays) {
        return true
      }
      const diff = dayjs(this.endDate, 'DD/MM/YYYY').diff(
        dayjs(this.startDate, 'DD/MM/YYYY'),
        'days',
        true
      )
      return diff < this.requestedItemData.MaxReservationDays
    }
  },
  watch: {
    item: async function() {
      await this.refreshItemData()
    },
    warehouse: async function() {
      await this.refreshItemData()
    },
    endDate: async function() {
      await this.refreshItemData()
    }
  },
  async created() {
    this.itemPlannerLoaded = true
    this.initialLoad = false
  },
  methods: {
    // TODO make it so that max items is 20% of total stock if no max items is provided
    getBus: function() {
      return this.bus
    },
    changeAmount: function({ amount, maxStock }) {
      if (amount > 0) {
        this.amount = Math.min(amount, maxStock)
      }
    },

    async addToCartRecursive() {
      this.updatingItem = true

      for (let index in this.$refs.itemPlanner
        .filter(item => item.warehouse.WarehouseID == this.selectedWarehouse)
        .filter(item => item.warehouse.HasStock)) {
        let item = this.$refs.itemPlanner[index].item
        let selectedWarehouse = this.$refs.itemPlanner[index].selectedWarehouse
        let amount = await this.$store.getters.getItemStoreItem(
          `${item.ID}-${selectedWarehouse}`
        ).Amount

        await this.$refs.itemPlanner[index].addToCart(
          this.selectedWarehouse,
          amount
        )
        this.$store.commit('resetStoreItem', {
          key: `${item.ID}-${selectedWarehouse}`
        })
      }
    },
    refreshItemData: async function() {
      if (!this.warehouse) return
      if (!this.endDate) return

      this.updatingItem = true
      this.requestedItemData = await getItemStock({
        itemID: this.$route.params.itemID,
        startDate: this.startDate,
        endDate: this.endDate,
        slug: true
      })
      this.updatingItem = false
    },
    preventInput($event) {
      $event.preventDefault()
    }
  }
}
</script>
<style scoped lang="scss">
.medium-description {
  margin-top: -20px;
}
.datepicker {
  width: 100%;
}
.nav-tabs {
  width: 100%;
  margin-left: -20px;
}

.partially-available {
  h5 {
    font-family: 'Roboto';
    font-size: 18px;
  }
  p {
    font-family: 'Roboto';
    font-size: 14px;
  }
}
</style>
